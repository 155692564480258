.shownotes {
  padding: 15px;
  border-color: grey;
  border-style: solid;
  border-radius: 8px;
  font-size: 18px;
  }

ul{
  text-align: left;
  padding-inline-start: 15px;
}

a {
  color:white;
  cursor: pointer;
  text-decoration: underline;
}
