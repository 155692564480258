.rw-box {
  display: flex;
}

.rw-main {
  /* background-color: green; */
  padding: 15px;
  border-color: grey;
  border-style: solid;
  border-radius: 8px;
}

.rw-box-days {
  /* background-color: red; */
  display: flex;
  flex-wrap: wrap;
  border-color: grey;
  border-style: solid;
  border-radius: 8px;
}

.rw-day {
  padding: 10px;
}