.App {
  text-align: center;
  min-height:  100vh;
  background-color: #282c34;
  color: white;
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

}

.App-components {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding-left: 10px;
  padding-right: 10px;
}
